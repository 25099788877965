(function(jQuery) {
	
	"use strict";	
	
	//Update Header Style and Scroll to Top
	function headerStyle() {
		if(jQuery('.main-header').length){
			var windowpos = jQuery(window).scrollTop();
			var siteHeader = jQuery('.main-header');
			var scrollLink = jQuery('.scroll-to-top');
			if (windowpos >= 200) {
				siteHeader.addClass('fixed-header');
				scrollLink.fadeIn(300);
			} else {
				siteHeader.removeClass('fixed-header');
				scrollLink.fadeOut(300);
			}
		}
	}
	
	headerStyle();
	
	
	// Fact Counter
	function factCounter() {
		if(jQuery('.fact-counter').length){
			jQuery('.fact-counter .counter-column.animated').each(function() {

				var jQueryt = jQuery(this),
					n = jQueryt.find(".count-text").attr("data-stop"),
					r = parseInt(jQueryt.find(".count-text").attr("data-speed"), 10);

				if (!jQueryt.hasClass("counted")) {
					jQueryt.addClass("counted");
					jQuery({
						countNum: jQueryt.find(".count-text").text()
					}).animate({
						countNum: n
					}, {
						duration: r,
						easing: "linear",
						step: function() {
							jQueryt.find(".count-text").text(Math.floor(this.countNum));
						},
						complete: function() {
							jQueryt.find(".count-text").text(this.countNum);
						}
					});
				}

			});
		}
	}
	
	
	//Tabs Box
	if(jQuery('.tabs-box').length){
		jQuery('.tabs-box .tab-buttons .tab-btn').on('click', function(e) {
			e.preventDefault();
			var target = jQuery(jQuery(this).attr('data-tab'));
			
			if (jQuery(target).is(':visible')){
				return false;
			}else{
				target.parents('.tabs-box').find('.tab-buttons').find('.tab-btn').removeClass('active-btn');
				jQuery(this).addClass('active-btn');
				target.parents('.tabs-box').find('.tabs-content').find('.tab').fadeOut(0);
				target.parents('.tabs-box').find('.tabs-content').find('.tab').removeClass('active-tab');
				jQuery(target).fadeIn(300);
				jQuery(target).addClass('active-tab');
			}
		});
	}
	
	
	//Date Picker
	if(jQuery('.datepicker').length){
		jQuery( '.datepicker' ).datepicker();
	}
	
	//LightBox / Fancybox
	if(jQuery('.lightbox-image').length) {
		jQuery('.lightbox-image').fancybox({
			openEffect  : 'fade',
			closeEffect : 'fade',
			helpers : {
				media : {}
			}
		});
	}
	
	
	//Contact Form Validation
	// if(jQuery('#contact-form').length){
	// 	jQuery('#contact-form').validate({
	// 		rules: {
	// 			firstname: {
	// 				required: true
	// 			},
	// 			lastname: {
	// 				required: true
	// 			},
	// 			email: {
	// 				required: true,
	// 				email: true
	// 			},
	// 			phone: {
	// 				required: true
	// 			},
	// 			message: {
	// 				required: true
	// 			}
	// 		}
	// 	});
	// }
	
	
	// Product Carousel
	if (jQuery('.prod-image-carousel').length && jQuery('.prod-thumbs-carousel').length) {

		var jQuerysync3 = jQuery(".prod-image-carousel"),
			jQuerysync4 = jQuery(".prod-thumbs-carousel"),
			flag = false,
			duration = 500;

			jQuerysync3
				.owlCarousel({
					loop:false,
					items: 1,
					margin: 0,
					nav: true,
					navText: [ '<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>' ],
					dots: false,
					autoplay: true,
					autoplayTimeout: 5000
				})
				.on('changed.owl.carousel', function (e) {
					if (!flag) {
						flag = false;
						jQuerysync4.trigger('to.owl.carousel', [e.item.index, duration, true]);
						flag = false;
					}
				});

			jQuerysync4
				.owlCarousel({
					loop:false,
					margin: 20,
					items: 1,
					nav: false,
					navText: [ '<span class="icon fa fa-long-arrow-left"></span>', '<span class="icon fa fa-long-arrow-right"></span>' ],
					dots: false,
					center: false,
					autoplay: true,
					autoplayTimeout: 5000,
					responsive: {
						0:{
				            items:1,
				            autoWidth: false
				        },
				        400:{
				            items:2,
				            autoWidth: false
				        },
				        600:{
				            items:3,
				            autoWidth: false
				        },
				        1000:{
				            items:4,
				            autoWidth: false
				        },
						1200:{
				            items:4,
				            autoWidth: false
				        }
				    },
				})
				
		.on('click', '.owl-item', function () {
			jQuerysync3.trigger('to.owl.carousel', [jQuery(this).index(), duration, true]);
		})
		.on('changed.owl.carousel', function (e) {
			if (!flag) {
				flag = true;		
				jQuerysync3.trigger('to.owl.carousel', [e.item.index, duration, true]);
				flag = false;
			}
		});

	}
	
	
	//Price Range Slider
	if(jQuery('.range-slider-price').length){

		var priceRange = document.getElementById('range-slider-price');

		noUiSlider.create(priceRange, {
			start: [ 100, 500 ],
			limit: 2000,
			behaviour: 'drag',
			connect: true,
			range: {
				'min': 100,
				'max': 2000
			}
		});

		var limitFieldMin = document.getElementById('min-value-rangeslider');
		var limitFieldMax = document.getElementById('max-value-rangeslider');

		priceRange.noUiSlider.on('update', function( values, handle ){
			(handle ? limitFieldMax : limitFieldMin).value = values[handle];
		});
	}
	
	
	//Hide Confirmation Popup
	if(jQuery('.popup-outer').length){
		jQuery('.popup-outer .close-btn a').on('click', function(e) {
			e.preventDefault();
			jQuery('.backdrop-overlay').fadeOut(500);
			jQuery('.popup-outer').fadeOut(500);
			jQuery('body').removeClass('popup-visible');
		});
	}
	
	
	// Scroll to a Specific Div
	if(jQuery('.scroll-to-target').length){
		jQuery(".scroll-to-target").on('click', function() {
			var target = jQuery(this).attr('data-target');
		   // animate
		   jQuery('html, body').animate({
			   scrollTop: jQuery(target).offset().top
			 }, 1000);
	
		});
	}
	
	
	// Elements Animation
	if(jQuery('.wow').length){
		var wow = new WOW(
		  {
			boxClass:     'wow',      // animated element css class (default is wow)
			animateClass: 'animated', // animation css class (default is animated)
			offset:       0,          // distance to the element when triggering the animation (default is 0)
			mobile:       false,       // trigger animations on mobile devices (default is true)
			live:         true       // act on asynchronously loaded content (default is true)
		  }
		);
		wow.init();
	}


/* ==========================================================================
   When document is Scrollig, do
   ========================================================================== */
	
	jQuery(window).on('scroll', function() {
		headerStyle();
		factCounter();
	});

	

})(window.jQuery);