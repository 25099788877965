<template>
  <!--Call To Action Footer-->
  <div class="call-to-action-footer">
    <div class="auto-container">
      <div class="row clearfix">
        <!--Left Column-->
        <div class="left-column col-lg-5 col-md-12 col-sm-12 col-xs-12">
          <div class="inner-box">
            <div class="icon-box"><span class="fa fa-phone"></span></div>
            Agenda nuestro Telefono: <strong>{{$parent.phones[0].phone}}</strong>
          </div>
        </div>
        <!--Right Column-->
        <div class="right-column col-lg-7 col-md-12 col-sm-12 col-xs-12">
          <div class="inner-box">
            <div class="content-box">
              <figure class="logo-box">
                <a href="index-2.html"
                  ><img src="@/assets/images/logo-5.png" alt=""
                /></a>
              </figure>
              <div class="text">
                Si necesitas programar un viaje ya sea por compromisos o por tu llegada
                al Aeropuerto Internacional Tte. Luis Candelaria de San Carlos de Bariloche,
                comunicate a nuestros telefonos y hace tu reserva.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterPhone",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
