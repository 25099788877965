<template>
  <!--CallUs Section-->
    <section class="callus-section" :style="{'background-image': 'url(' + require('../assets/images/resource/foto_autos_2.jpg') + ')'}">
    	<div class="auto-container">
        	<div class="logo"><a href="index-2.html"><img src="@/assets/images/logo-2.png" alt=""></a></div>
            <!--Heading Title / Light Version-->
            <div class="sec-title centered light-version">
                <h2>Comunicate con nosotros por Whatsapp</h2>
                <div class="desc-text">Hace click en el numero de abajo y habla con nosotros ahora!</div>
                <a :href="$parent.$parent.phones[1].link" class="actioncall">
                    <span class="fa fa-whatsapp"></span> Enviar mensaje ahora
                </a>
                <p class="number"> {{$parent.$parent.phones[1].phone}} </p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: "CallUs",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
