<template>
  <section class="gallery-section">
    <div class="auto-container">
      <!--Heading Title-->
      <div class="sec-title centered">
        <div class="icon-box"><span class="flaticon-sports-car"></span></div>
        <h2>Nuestros Autos</h2>
        <div class="desc-text">
          Estos son algunos de los vehiculos que integran nuestra flota.
        </div>
      </div>

      <div class="mixitup-gallery">
        <!--Filter-->
        <div class="filters text-center">
          <ul class="filter-tabs filter-btns clearfix">
            <li class="active filter" data-role="button" data-filter="all">
              Todos
            </li>
            <li class="filter" data-role="button" data-filter=".sedan">
              Sedan
            </li>
            <li class="filter" data-role="button" data-filter=".camioneta">
              Camioneta
            </li>
            <li class="filter" data-role="button" data-filter=".van">Van</li>
          </ul>
        </div>

        <!--Filter List-->
        <div class="filter-list">
          <!--Default Portfolio Item-->
          <div
            :class="
              'col-lg-4 col-md-4 col-sm-6 col-xs-12 default-portfolio-item mix mix_all ' +
              image.category
            "
            v-for="image in gallery"
            :key="image._id"
          >
            <div class="inner-box">
              <figure class="image-box">
                <img :src="image.thumb" alt="" />
              </figure>
              <!--Overlay Box-->
              <div class="overlay-box">
                <div class="overlay-inner">
                  <div class="content">
                    <h3>{{ image.title }}</h3>
                    <div class="cat-links">San Carlos de Bariloche</div>
                    <a
                      :href="image.image"
                      class="lightbox-image option-btn"
                      :title="image.title"
                      data-fancybox-group="cars-gallery"
                      ><span class="fa fa-search"></span
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </section>
</template>

<script>
import "../assets/js/mixitup.js";

export default {
  name: "Gallery",
  data() {
    return {
      gallery: [
        {
          title: "Flota completa",
          category: "all",
          thumb: require("../assets/images/gallery/thumb/1.jpg"),
          image: require("../assets/images/gallery/1.jpg"),
        },
        {
          title: "Flota completa",
          category: "all",
          thumb: require("../assets/images/gallery/thumb/2.jpg"),
          image: require("../assets/images/gallery/2.jpg"),
        },
        {
          title: "Flota completa",
          category: "all",
          thumb: require("../assets/images/gallery/thumb/3.jpg"),
          image: require("../assets/images/gallery/3.jpg"),
        },
        {
          title: "Sedan 5 Puertas",
          category: "all sedan",
          thumb: require("../assets/images/gallery/thumb/4.jpg"),
          image: require("../assets/images/gallery/4.jpg"),
        },
        {
          title: "Camioneta",
          category: "all camioneta",
          thumb: require("../assets/images/gallery/thumb/5.jpg"),
          image: require("../assets/images/gallery/5.jpg"),
        },
        {
          title: "Sedan 5 Puertas",
          category: "all sedan",
          thumb: require("../assets/images/gallery/thumb/6.jpg"),
          image: require("../assets/images/gallery/6.jpg"),
        },
        {
          title: "Camioneta",
          category: "all camioneta",
          thumb: require("../assets/images/gallery/thumb/7.jpg"),
          image: require("../assets/images/gallery/7.jpg"),
        },
        {
          title: "Sedan 5 Puertas",
          category: "all sedan",
          thumb: require("../assets/images/gallery/thumb/8.jpg"),
          image: require("../assets/images/gallery/8.jpg"),
        },
        {
          title: "Sedan 5 Puertas",
          category: "all sedan",
          thumb: require("../assets/images/gallery/thumb/9.jpg"),
          image: require("../assets/images/gallery/9.jpg"),
        },
        {
          title: "Sedan 5 Puertas",
          category: "all sedan",
          thumb: require("../assets/images/gallery/thumb/10.jpg"),
          image: require("../assets/images/gallery/10.jpg"),
        },
        {
          title: "Camioneta",
          category: "all camioneta",
          thumb: require("../assets/images/gallery/thumb/11.jpg"),
          image: require("../assets/images/gallery/11.jpg"),
        },
        {
          title: "Flota completa",
          category: "all",
          thumb: require("../assets/images/gallery/thumb/12.jpg"),
          image: require("../assets/images/gallery/12.jpg"),
        },
        {
          title: "Flota completa",
          category: "all",
          thumb: require("../assets/images/gallery/thumb/13.jpg"),
          image: require("../assets/images/gallery/13.jpg"),
        },
        {
          title: "Van espacio para 6",
          category: "all van",
          thumb: require("../assets/images/gallery/thumb/14.jpg"),
          image: require("../assets/images/gallery/14.jpg"),
        },
        {
          title: "Van espacio para 6",
          category: "all van",
          thumb: require("../assets/images/gallery/thumb/15.jpg"),
          image: require("../assets/images/gallery/15.jpg"),
        },
      ],
    };
  },
  methods: {
    enableMasonry() {
      jQuery(".filter-list").mixItUp({});
    },
  },
  mounted() {
    const self = this;

    $(document).ready(function () {
      self.enableMasonry();

      jQuery(".lightbox-image").fancybox({
        openEffect: "fade",
        closeEffect: "fade",
        helpers: {
          media: {},
        },
      });
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
