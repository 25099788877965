<template>
  <div>
    <Hero/>
    <Welcome/>
    <CallUs/>
    <Gallery/>
    <!-- <Testimonials/> -->
  </div>
</template>

<script>
// @ is an alias to /src
// Modules Import
import Welcome from '../components/Welcome.vue'
import Hero from '../components/Hero.vue'
import CallUs from '../components/CallUs.vue'
import Gallery from '../components/Gallery.vue'
// import Testimonials from '../components/Testimonials.vue'

export default {
  name: 'Home',
  components: {
    Hero,
    Welcome,
    CallUs,
    Gallery,
    // Testimonials,
  },
}
</script>

<style>
    
    
</style>