<template>
  <!--Main Slider-->
  <section class="main-slider" data-start-height="800" data-slide-overlay="yes">
    <div class="tp-banner-container">
      <div class="tp-banner">
        <ul>
          <li
            data-transition="fade"
            data-slotamount="1"
            data-masterspeed="1000"
            data-thumb="images/main-slider/1.jpg"
            data-saveperformance="off"
            data-title="Awesome Title Here"
          >
            <img
              src="@/assets/images/main-slider/1.jpg"
              alt=""
              data-bgposition="center top"
              data-bgfit="cover"
              data-bgrepeat="no-repeat"
            />

            <div
              class="tp-caption sfl sfb tp-resizeme"
              data-x="left"
              data-hoffset="100"
              data-y="center"
              data-voffset="-90"
              data-speed="1500"
              data-start="0"
              data-easing="easeOutExpo"
              data-splitin="none"
              data-splitout="none"
              data-elementdelay="0.01"
              data-endelementdelay="0.3"
              data-endspeed="1200"
              data-endeasing="Power4.easeIn"
            >
              <div class="small-title">Amplia Cobertura en San Carlos de Bariloche</div>
            </div>

            <div
              class="tp-caption sfr sfb tp-resizeme"
              data-x="left"
              data-hoffset="100"
              data-y="center"
              data-voffset="10"
              data-speed="1500"
              data-start="0"
              data-easing="easeOutExpo"
              data-splitin="none"
              data-splitout="none"
              data-elementdelay="0.01"
              data-endelementdelay="0.3"
              data-endspeed="1200"
              data-endeasing="Power4.easeIn"
            >
              <h2 class="big-title">
                Traslados de corta y larga distancia.
              </h2>
            </div>

            <div
              class="tp-caption sfl sfb tp-resizeme"
              data-x="left"
              data-hoffset="100"
              data-y="center"
              data-voffset="130"
              data-speed="1500"
              data-start="0"
              data-easing="easeOutExpo"
              data-splitin="none"
              data-splitout="none"
              data-elementdelay="0.01"
              data-endelementdelay="0.3"
              data-endspeed="1200"
              data-endeasing="Power4.easeIn"
            >
              <a :href="$parent.$parent.phones[1].link + '?text=Hola!%20Quiero%20pedir%20un%20remis.'" target="_blank" class="theme-btn btn-style-one">
                <span class="fa fa-whatsapp"></span> Pedir un Remis</a>
            </div>
          </li>

          <li
            data-transition="zoomin"
            data-slotamount="1"
            data-masterspeed="1000"
            data-thumb="images/main-slider/2.jpg"
            data-saveperformance="off"
            data-title="Awesome Title Here"
          >
            <img
              src="@/assets/images/main-slider/2.jpg"
              alt=""
              data-bgposition="center top"
              data-bgfit="cover"
              data-bgrepeat="no-repeat"
            />

            <div
              class="tp-caption sfr sfb tp-resizeme"
              data-x="center"
              data-hoffset="0"
              data-y="center"
              data-voffset="-90"
              data-speed="1500"
              data-start="0"
              data-easing="easeOutExpo"
              data-splitin="none"
              data-splitout="none"
              data-elementdelay="0.01"
              data-endelementdelay="0.3"
              data-endspeed="1200"
              data-endeasing="Power4.easeIn"
            >
              <div class="small-title">Recepción y traslado al aeropuerto</div>
            </div>

            <div
              class="tp-caption sfl sfb tp-resizeme"
              data-x="center"
              data-hoffset="0"
              data-y="center"
              data-voffset="10"
              data-speed="1500"
              data-start="0"
              data-easing="easeOutExpo"
              data-splitin="none"
              data-splitout="none"
              data-elementdelay="0.01"
              data-endelementdelay="0.3"
              data-endspeed="1200"
              data-endeasing="Power4.easeIn"
            >
              <div class="text-center">
                <h2 class="big-title">
                  Te buscamos en el aeropuerto y <br> llevamos al lugar que te hospedes.
                </h2> 
              </div>
            </div>

            <div
              class="tp-caption sfr sfb tp-resizeme"
              data-x="center"
              data-hoffset="0"
              data-y="center"
              data-voffset="130"
              data-speed="1500"
              data-start="0"
              data-easing="easeOutExpo"
              data-splitin="none"
              data-splitout="none"
              data-elementdelay="0.01"
              data-endelementdelay="0.3"
              data-endspeed="1200"
              data-endeasing="Power4.easeIn"
            >
              <a :href="$parent.$parent.phones[1].link + '?text=Hola!%20Quiero%20hacer%20una%20reserva%20para%20el%20aeropuerto.'" target="_blank" class="theme-btn btn-style-one">
                <span class="fa fa-whatsapp"></span> Hacer una reserva</a>
            </div>
          </li>

          <li
            data-transition="zoomout"
            data-slotamount="1"
            data-masterspeed="1000"
            data-thumb="images/main-slider/3.jpg"
            data-saveperformance="off"
            data-title="Awesome Title Here"
          >
            <img
              src="@/assets/images/main-slider/3.jpg"
              alt=""
              data-bgposition="center top"
              data-bgfit="cover"
              data-bgrepeat="no-repeat"
            />

            <div
              class="tp-caption sft sfb tp-resizeme"
              data-x="center"
              data-hoffset="0"
              data-y="center"
              data-voffset="-110"
              data-speed="1500"
              data-start="0"
              data-easing="easeOutExpo"
              data-splitin="none"
              data-splitout="none"
              data-elementdelay="0.01"
              data-endelementdelay="0.3"
              data-endspeed="1200"
              data-endeasing="Power4.easeIn"
            >
              <div class="small-title">Podemos llevar en un mismo viaje a todos juntos.</div>
            </div>

            <div
              class="tp-caption sfb sfb tp-resizeme"
              data-x="center"
              data-hoffset="0"
              data-y="center"
              data-voffset="10"
              data-speed="1500"
              data-start="0"
              data-easing="easeOutExpo"
              data-splitin="none"
              data-splitout="none"
              data-elementdelay="0.01"
              data-endelementdelay="0.3"
              data-endspeed="1200"
              data-endeasing="Power4.easeIn"
            >
              <div class="text-center">
                <h2 class="big-title">
                  Servicio de VAN hasta 6 pasajeros <br> con equipaje incluido
                </h2>
              </div>
            </div>

            <div
              class="tp-caption sfb sfb tp-resizeme"
              data-x="center"
              data-hoffset="0"
              data-y="center"
              data-voffset="150"
              data-speed="1500"
              data-start="0"
              data-easing="easeOutExpo"
              data-splitin="none"
              data-splitout="none"
              data-elementdelay="0.01"
              data-endelementdelay="0.3"
              data-endspeed="1200"
              data-endeasing="Power4.easeIn"
            >
              <a :href="$parent.$parent.phones[1].link + '?text=Hola%21Quiero+contratar+el+servicio+de+VAN'" target="_blank" class="theme-btn btn-style-one">
                <span class="fa fa-whatsapp"></span> Contratar una VAN</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "Hero",
  props: {},
  data() {
    return {
    };
  },
  methods: {
    loadRevolution() {
      setTimeout(()=>{var MainSlider = $(".main-slider");
      var strtHeight = MainSlider.attr("data-start-height");
      var slideOverlay = "'" + MainSlider.attr("data-slide-overlay") + "'";

      $(".main-slider .tp-banner")
        .show()
        .revolution({
          dottedOverlay: slideOverlay,
          delay: 100000,
          startwidth: 1200,
          startheight: strtHeight,
          hideThumbs: 600,

          thumbWidth: 80,
          thumbHeight: 50,
          thumbAmount: 5,

          navigationType: "bullet",
          navigationArrows: "0",
          navigationStyle: "preview3",

          touchenabled: "on",
          onHoverStop: "off",

          swipe_velocity: 0.7,
          swipe_min_touches: 1,
          swipe_max_touches: 1,
          drag_block_vertical: false,

          parallax: "mouse",
          parallaxBgFreeze: "on",
          parallaxLevels: [7, 4, 3, 2, 5, 4, 3, 2, 1, 0],

          keyboardNavigation: "off",

          navigationHAlign: "center",
          navigationVAlign: "bottom",
          navigationHOffset: 0,
          navigationVOffset: 40,

          soloArrowLeftHalign: "left",
          soloArrowLeftValign: "center",
          soloArrowLeftHOffset: 20,
          soloArrowLeftVOffset: 20,

          soloArrowRightHalign: "right",
          soloArrowRightValign: "center",
          soloArrowRightHOffset: 20,
          soloArrowRightVOffset: 20,

          shadow: 0,
          fullWidth: "on",
          fullScreen: "off",

          spinner: "spinner4",

          stopLoop: "off",
          stopAfterLoops: -1,
          stopAtSlide: -1,

          shuffle: "off",

          autoHeight: "off",
          forceFullWidth: "on",

          hideThumbsOnMobile: "on",
          hideNavDelayOnMobile: 1500,
          hideBulletsOnMobile: "on",
          hideArrowsOnMobile: "on",
          hideThumbsUnderResolution: 0,

          hideSliderAtLimit: 0,
          hideCaptionAtLimit: 0,
          hideAllCaptionAtLilmit: 0,
          startWithSlide: 0,
          videoJsPath: "",
          fullScreenOffsetContainer: "",
        });}, 100);
      
    },
  },
  beforeMount() {
    const self = this;
    console.log("loadRevolution");
    $(document).ready(function () {
      self.loadRevolution();
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
