<template>
  <div id="app">
    <div class="page-wrapper">
      <div class="preloader"></div>
      <Header />
      <router-view />
      <FooterPhone />
      <Footer />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// Modules Import
import Header from "./components/Header.vue";
import FooterPhone from "./components/FooterPhone.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "Home",
  components: {
    Header,
    FooterPhone,
    Footer,
  },
  data() {
    return {
      menu: [
        { route: "/" },
        { route: "/nosotros" },
        { route: "/servicios" },
        { route: "/contacto" },
      ],
      phones: [
        { type: "Telefono", link: "tel:+5492944433100", phone: '+54 (0294) 4433100' },
        { type: "Whatsapp", link: "https://wa.me/+5492944146600", phone: '+54 (0294) 4146600'},
        //{ type: "Whatsapp", link: "https://wa.me/+5492944638282", phone: '+54 (0294) 4638282'},
      ],
      socials: [
        { link: "https://www.facebook.com/remisescentrobrc", icon: "fa fa-facebook-f", name: "facebook" },
        { link: "https://www.instagram.com/remisescentrobariloche/", icon: "fa fa-instagram", name: "instagram" },
      ],
    };
  },
  mounted(){
    jQuery(window).on('load', function() {
		  jQuery('.preloader').delay(200).fadeOut(500);
	  });    
  }
};
</script>

<style>
  @import '../node_modules/vue2-timepicker/dist/VueTimepicker.css';
  @import './assets/css/bootstrap.css';
  @import './assets/css/jquery-ui.css';
  @import "./assets/css/revolution-slider.css";
  @import "./assets/css/style.css";
  @import './assets/css/responsive.css';
</style>
