<template>
  <!--Main Footer-->
  <footer class="main-footer">
    <!--Widgets Section-->
    <div class="widgets-section">
      <div class="auto-container">
        <div class="row clearfix">
          <div class="footer-column col-md-4 col-sm-6 col-xs-12">
            <div class="footer-widget work-hours-widget">
              <img src="@/assets/images/logo-2.png" style="max-width: 250px" />
              <div class="widget-content">
                <strong>Horarios de Apertura</strong>
                <div class="text">Estamos abiertos las 24/7 todo el año.</div>
                <!--Social Links-->
                <div class="social-links">
                  Nustras redes sociales | 
                  <a v-for="social in $parent.socials" :key='social._id' :href="social.link"><span :class="social.icon"></span></a>
                </div>
              </div>
            </div>
          </div>
          <div class="footer-column col-md-4 col-sm-6 col-xs-12">
            <div class="footer-widget links-widget">
              <h2>Mapa del sitio</h2>
              <div class="widget-content">
                <ul class="list">
                  <router-link
                    v-for="item in $parent.menu"
                    :key="item._id"
                    :to="item.route"
                    v-slot="{ href, route, navigate }"
                    custom
                  >
                    <li>
                      <a :href="href" @click="navigate">{{ route.name }}</a>
                    </li>
                  </router-link>
                </ul>
              </div>
            </div>
          </div>
          <div class="footer-column col-md-4 col-sm-12 col-xs-12">
            <div class="footer-widget links-widget">
              <h2>Telefonos</h2>
              <div class="widget-content">
                <ul class="list">
                  <li v-for="phone in $parent.phones" :key="phone._id">
                    <a :href="phone.link">{{phone.type}}: {{ phone.phone }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Footer Bottom-->
    <div class="footer-bottom">
      <div class="auto-container">
        <div class="text">
          Copyrights &copy; 2021
          <a href="https://www.remisescentro.com.ar">Remises Centro</a>. Todos
          los derechos Reservados - Desarrollado por
          <a href="https://www.incubit.com.ar">Incubit</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
