import Vue from 'vue'
import App from './App.vue'
import router from './router'
//import i18n from './lang/i18n'
import './assets/js/jquery-ui.js'
import './assets/js/bootstrap.min.js'
import './assets/js/jquery.fancybox.pack.js'
import './assets/js/jquery.fancybox-media.js'
import './assets/js/isotope.js'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './assets/js/wow.js'
import "./assets/js/revolution.min.js"
import "./assets/js/script.js"


// Main JS (in UMD format)
import VueTimepicker from 'vue2-timepicker'
// CSS
Vue.component('vue-timepicker', VueTimepicker);

Vue.config.productionTip = false

const app = new Vue({
  router,
  render: h => h(App)
})
//app.use(i18n)
app.$mount('#app')
