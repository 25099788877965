<template>
  <!-- Main Header / Header Style One-->
  <header class="main-header header-style-one">
    <!--Header-Upper-->
    <div class="header-upper">
      <div class="auto-container">
        <div class="clearfix">
          <div class="logo-outer">
            <div class="logo">
              <router-link to="home"
                ><img
                  src="@/assets/images/logo.png"
                  alt="Remises Centro"
                  title="Remises Centro"
              /></router-link>
            </div>
          </div>

          <div class="upper-right clearfix">
            <!--Info Box-->
            <div class="info-box">
              <div class="icon-box">
                <span class="flaticon-buildings"></span>
              </div>
              <ul>
                <li><strong>Dirección:</strong></li>
                <li>Elflein 7 - San Carlos de Bariloche</li>
              </ul>
            </div>

            <!--Info Box-->
            <div class="info-box">
              <div class="icon-box">
                <a :href="$parent.phones[0].link">
                  <span class="flaticon-technology-5"></span>
                </a>
              </div>
              <ul>
                <li><strong>Llamanos Ahora:</strong></li>
                <li><a :href="$parent.phones[0].link" class="text-underline">{{$parent.phones[0].phone}}</a></li>
              </ul>
            </div>

            <div class="info-box">
              <div class="icon-box">
                <a :href="$parent.phones[1].link" target="_blank">
                  <span class="fa fa-whatsapp"></span>
                </a>
              </div>
              <ul>
                <li><strong>Enviar un Whatsapp:</strong></li>
                <li><a :href="$parent.phones[1].link" target="_blank" class="text-underline">{{$parent.phones[1].phone}}</a></li>
                <!-- <li><a :href="$parent.phones[2].link" target="_blank" class="text-underline">{{$parent.phones[2].phone}}</a></li> -->
              </ul>
            </div>

            <div class="info-box btn-box">
              <a
                :href="$parent.phones[1].link"
                target="_blank"
                class="theme-btn"
                ><span class="fa fa-whatsapp"></span> Enviar Whatsapp</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Header-Lower-->
    <div class="header-lower">
      <div class="auto-container">
        <div class="nav-outer clearfix">
          <!-- Main Menu -->
          <nav class="main-menu">
            <div class="navbar-header">
              <!-- Toggle Button -->
              <button
                type="button"
                class="navbar-toggle"
                data-toggle="collapse"
                data-target=".navbar-collapse"
              >
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>
            </div>

            <div class="navbar-collapse collapse clearfix">
              <ul class="navigation clearfix">
                <router-link v-for="item in $parent.menu" :key='item._id' :to="item.route" v-slot="{ href, route, navigate, isExactActive}" custom>
                <li :class="[isExactActive && 'current']">
                    <a :href="href" @click="navigate">{{ route.name }}</a>
                </li>
                </router-link>
              </ul>
            </div>
          </nav>
          <!-- Main Menu End-->

          <!--Social Links-->
          <div class="social-links">
            <a v-for="social in $parent.socials" :key='social._id' :href="social.link"><span :class="social.icon"></span></a>
          </div>
        </div>
      </div>
    </div>

    <!--Sticky Header-->
    <div class="sticky-header">
      <div class="auto-container clearfix">
        <!--Logo-->
        <div class="logo pull-left">
          <a href="index-2.html" class="img-responsive"
            ><img
              src="@/assets/images/logo-small.png"
              alt="Remises Centro"
              title="Remises Centro"
          /></a>
        </div>

        <!--Right Col-->
        <div class="right-col pull-right">
          <!-- Main Menu -->
          <nav class="main-menu">
            <div class="navbar-header">
              <!-- Toggle Button -->
              <button
                type="button"
                class="navbar-toggle"
                data-toggle="collapse"
                data-target=".navbar-collapse"
              >
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>
            </div>

            <div class="navbar-collapse collapse clearfix">
              <ul class="navigation clearfix">
                <router-link v-for="item in $parent.menu" :key='item._id' :to="item.route" v-slot="{ href, route, navigate, isActive}" custom>
                <li :class="[isActive && 'current']">
                    <a :href="href" @click="navigate">{{ route.name }}</a>
                </li>
                </router-link>
              </ul>
            </div>
          </nav>
          <!-- Main Menu End-->
        </div>
      </div>
    </div>
    <!--End Sticky Header-->
  </header>
  <!--End Main Header -->
</template>

<script>
export default {
  name: "Header",
  props: {},
  methods: {
    viewLng: function () {
      for (let lang of this.$data.langs) {
        if (lang.lang == this.$i18n.locale) return lang.icon;
      }
    },
    changeLng: function (lang) {
      this.$i18n.locale = lang;
    },
    scrollTo: function (e) {
      //const $ = jQuery;
    },
  },
  data() {
    return {
      langs: [
        { lang: "de", icon: require("../assets/images/lang/de.svg") },
        { lang: "en", icon: require("../assets/images/lang/en.svg") },
        { lang: "es", icon: require("../assets/images/lang/es.svg") },
        { lang: "it", icon: require("../assets/images/lang/it.svg") },
        { lang: "fr", icon: require("../assets/images/lang/fr.svg") },
      ],
    };
  },
  created() {
    $(document).ready(function () {
      if (jQuery(".main-header li.dropdown ul").length) {
        jQuery(".main-header li.dropdown").append(
          '<div class="dropdown-btn"><span class="fa fa-angle-down"></span></div>'
        );

        //Dropdown Button
        jQuery(".main-header li.dropdown .dropdown-btn").on(
          "click",
          function () {
            jQuery(this).prev("ul").slideToggle(500);
          }
        );

        //Disable dropdown parent link
        jQuery(".navigation li.dropdown > a").on("click", function (e) {
          e.preventDefault();
        });
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
