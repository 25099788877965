<template>
  <!-- Main Header / Header Style One-->
  <section class="welcome-section">
    <div class="auto-container">
      <div class="row clearfix">
        <!--Content Column-->
        <div class="content-column pull-right col-md-8 col-sm-12 col-xs-12">
          <!--Heading Title-->
          <div class="sec-title">
            <h2>Bienvenido a Remises Centro</h2>
            <div class="desc-text">
              Somos una empresa conformada por choferes que trabajan desde los comienzos de esta actividad, alla por el año 1993, en San Carlos de Bariloche.
            </div>
          </div>

          <div class="row clearfix">
            <!--Service Block-->
            <div class="service-block col-md-6 col-sm-6 col-xs-12">
              <div class="inner-box">
                <div class="icon-box"><span class="flaticon-clock"></span></div>
                <h3>Eficiencia, seguridad y confort.</h3>
                <div class="text">
                  Nuestros clientes nos eligen por la puntualidad, seguridad y el confort de los vehículos.
                </div>
              </div>
            </div>

            <!--Service Block-->
            <div class="service-block col-md-6 col-sm-6 col-xs-12">
              <div class="inner-box">
                <div class="icon-box"><span class="flaticon-plane"></span></div>
                <h3>Recepción en el Aeropuerto</h3>
                <div class="text">
                  Te buscamos o llevamos hacia el aeropuerto.
                </div>
              </div>
            </div>

            <!--Service Block-->
            <div class="service-block col-md-6 col-sm-6 col-xs-12" style="clear: both;">
              <div class="inner-box">
                <div class="icon-box"><span class="flaticon-car-2"></span></div>
                <h3>Amplia Cobertura</h3>
                <div class="text">
                  Traslados de corta y larga distancia.
                </div>
              </div>
            </div>

            <!--Service Block-->
            <div class="service-block col-md-6 col-sm-6 col-xs-12">
              <div class="inner-box">
                <div class="icon-box">
                  <span class="flaticon-delivery-truck"></span>
                </div>
                <h3>Entrega de Paquetes</h3>
                <div class="text">
                  Realizamos la búsqueda y distribución de paquetes.
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--Form Column-->
        <div class="form-column pull-left col-md-4 col-sm-12 col-xs-12">
          <div
            class="form-box wow fadeInUp"
            data-wow-delay="0ms"
            data-wow-duration="1500ms"
          >
            <!--Tabs Box-->
            <div class="tabs-box booking-tabs">
              <!--Tab Buttons-->
              <div class="tab-buttons clearfix">
                <div class="tab-btn active-btn">
                  <span class="txt"
                    ><span class="flaticon-car-3"></span> Hacer Reserva</span
                  >
                </div>
              </div>

              <!--Tabs Content-->
              <div class="tabs-content">
                <!--Tab / Active Tab-->
                <div class="tab active-tab" id="car-rental-tab">
                  <div class="default-form">
                    <form id="form-send-book" method="post">
                      <div class="field-label" title="Se requiere un numbre">
                        Su nombre <span class="req">*</span>
                      </div>
                      <div class="row clearfix">
                        <div class="form-group col-md-12 col-sm-12 col-xs-12">
                          <div class="field-inner">
                            <input
                              type="text"
                              v-model="formdata.fullname"
                              name="fullname"
                              id="fullname"
                              placeholder="Juan Perez"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="field-label">Desde Donde</div>
                      <div class="row clearfix">
                        <div class="form-group col-md-12 col-sm-12 col-xs-12">
                          <div class="field-inner">
                            <input
                              type="text"
                              v-model="formdata.addressfrom"
                              name="addressfrom"
                              placeholder="Aeropuerto o Calle"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="field-label">Hacia Donde</div>
                      <div class="row clearfix">
                        <div class="form-group col-md-12 col-sm-12 col-xs-12">
                          <div class="field-inner">
                            <input
                              type="text"
                              v-model="formdata.addressto"
                              name="addressto"
                              placeholder="Aeropuerto o Calle"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="field-label">Fecha y Hora</div>
                      <div class="row clearfix">
                        <div
                          class="
                            form-group
                            col-lg-6 col-md-12 col-sm-6 col-xs-12
                          "
                        >
                          <div class="field-inner">
                            <input
                              datepicker
                              type="text"
                              class="datepicker"
                              id="date"
                              v-model="formdata.date"
                              name="date"
                              placeholder="Fecha"
                              autocomplete="off"
                            /><label class="input-icon" for="field-date"
                              ><span class="fa fa-calendar"></span
                            ></label>
                          </div>
                        </div>
                        <div
                          class="
                            form-group
                            col-lg-6 col-md-12 col-sm-6 col-xs-12
                          "
                        >
                          <!-- <Timepicker /> -->
                          <div class="field-inner">
                            <vue-timepicker 
                            id="timepicker" 
                            v-model="formdata.time" 
                            format="HH:mm"
                            name="timepicker" 
                            placeholder="Hora"
                             :minute-interval="5" 
                            close-on-complete>
                            </vue-timepicker>
                          </div>
                        </div>
                      </div>
                      <div class="field-label">Pasajeros</div>
                      <div class="row clearfix">
                        <div
                          class="
                            form-group
                            col-lg-6 col-md-12 col-sm-6 col-xs-12
                          "
                        >
                          <div class="field-inner">
                            <select
                              id="adults"
                              name="adults"
                              placeholder="Adulto"
                              title="Indique cuantos adultos"
                              v-model="formdata.adults"
                            >
                              <option value="" disabled hidden selected>
                                Adulto
                              </option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                            </select>
                            <label class="input-icon" for="adults">
                              <span class="fa fa-user"></span>
                            </label>
                          </div>
                        </div>
                        <div
                          class="
                            form-group
                            col-lg-6 col-md-12 col-sm-6 col-xs-12
                          "
                        >
                          <div class="field-inner">
                            <select
                              id="childs"
                              name="childs"
                              placeholder="Niños"
                              v-model="formdata.childs"
                              title="Indique si hay ninños!"
                            >
                              <option value="" disabled hidden selected>
                                Niños
                              </option>
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                            </select>
                            <label class="input-icon" for="childs">
                              <span class="fa fa-user"></span>
                            </label>
                          </div>
                        </div>
                        <div class="form-group col-sm-12 col-xs-12">
                          <div class="check-box">
                            <input
                              type="checkbox"
                              id="equipe"
                              name="equipe"
                              v-model="formdata.equipe"
                            />
                            <label for="equipe">
                              <span class="icon">
                                <span class="square"></span>
                                <span class="fa fa-check"></span>
                              </span>
                              Con equipaje
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="row clearfix">
                        <div class="form-group col-sm-12 col-xs-12">
                          <button
                            type="submit"
                            class="theme-btn btn-style-three"
                          >
                            Hacer Reserva
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row clearfix payment-types">
        <div class="col-xs-12 text-center">
          <h4>Aceptamos pagos con Tarjeta de Credito, Debito y Mercado Pago</h4>
        </div>
        <div class="col-xs-12 text-center payment-box">
          <div class="payment-type-container">
            <img src="@/assets/images/payments-type/visa.png" />
          </div>
          <div class="payment-type-container">
            <img src="@/assets/images/payments-type/master.png" />
          </div>
          <div class="payment-type-container">
            <img src="@/assets/images/payments-type/mercadopago.png" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End Main Header -->
</template>

<script>
//Jquery Import
import jQuery from "jquery";
import "../assets/js/validate.js";

export default {
  name: "Contact",
  data() {
    return {
      formdata: {
        fullname: "",
        addressto: "",
        addressfrom: "",
        adults: "",
        childs: "",
        equipe: true,
        date: "",
        time: "",
      },
    };
  },
  methods: {
    translate(text) {
      return this.$t(text);
    },
    submitReserve() {
      const data = this.formdata;
      const child = data.childs;
      const adults = data.adults;
      var text = "Hola, mi nombre es " + data.fullname + " y quisiera hacer una reserva: %0a" +
                "Desde: " + data.addressfrom +  "%0a" +
                "Hasta: " + data.addressfrom + "%0a" +
                "Saliendo el día " + data.date + " a las " + data.time + "%0a" +
                data.adults  + " adulto" + (data.adults > 1 ? "s" : "") + 
                (data.childs > 0 ? " y " + data.childs  + " niño" + (data.childs > 1 ? "s" : "") + "%0a" : "%0a") +
                (data.equipe ? "Con" : "Sin") + " equipaje";
      window.open(this.$parent.$parent.phones[1].link + "?text=" + text, '_blank');
      this.formdata = {
        fullname: "",
        addressto: "",
        addressfrom: "",
        adults: "",
        childs: "",
        equipe: true,
        date: "",
        time: "",
      };
    },
  },
  mounted() {
    const self = this;
    $(document).ready(function () {
      jQuery(".datepicker").datepicker({
        onSelect: function (dateText) {
          self.formdata.date = dateText;
        },
      });
      jQuery("#form-send-book").validate({
        rules: {
          fullname: { required: true },
          addressto: { required: true },
          addressfrom: { required: true },
          adults: { required: true },
          childs: { required: true },
          date: { required: true },
          time: { required: true },
        },
        submitHandler: function (form, event) {
          event.preventDefault();
          self.submitReserve();
        },
      });
      // $('form.php-email-form').submit(function (e) {
      //     e.preventDefault();
      //     var f = $(this).find('.form-group'),
      //         ferror = false,
      //         emailExp = /^[^\s()<>@,;:]+@\w[\w.-]+\.[a-z]{2,}$/i;
      //     f.children('input').each(function () { // run all inputs
      //         var i = $(this); // current input
      //         var rule = i.attr('data-rule');
      //         if (rule !== undefined) {
      //             var ierror = false; // error flag for current input
      //             var pos = rule.indexOf(':', 0);
      //             if (pos >= 0) {
      //                 var exp = rule.substr(pos + 1, rule.length);
      //                 rule = rule.substr(0, pos);
      //             } else {
      //                 rule = rule.substr(pos + 1, rule.length);
      //             }
      //             switch (rule) {
      //                 case 'required':
      //                     if (i.val() === '') {
      //                         ferror = ierror = true;
      //                     }
      //                     break;
      //                 case 'minlen':
      //                     if (i.val().length < parseInt(exp)) {
      //                         ferror = ierror = true;
      //                     }
      //                     break;
      //                 case 'email':
      //                     if (!emailExp.test(i.val())) {
      //                         ferror = ierror = true;
      //                     }
      //                     break;
      //                 case 'checked':
      //                     if (!i.is(':checked')) {
      //                         ferror = ierror = true;
      //                     }
      //                     break;
      //                 case 'regexp':
      //                     exp = new RegExp(exp);
      //                     if (!exp.test(i.val())) {
      //                         ferror = ierror = true;
      //                     }
      //                     break;
      //             }
      //             i.next('.validate').html((ierror ? (i.attr('data-msg') !== undefined ? i.attr('data-msg') : 'wrong Input') : '')).show('blind');
      //         }
      //     });
      //     f.children('textarea').each(function () { // run all inputs
      //         var i = $(this); // current input
      //         var rule = i.attr('data-rule');
      //         if (rule !== undefined) {
      //             var ierror = false; // error flag for current input
      //             var pos = rule.indexOf(':', 0);
      //             if (pos >= 0) {
      //                 var exp = rule.substr(pos + 1, rule.length);
      //                 rule = rule.substr(0, pos);
      //             } else {
      //                 rule = rule.substr(pos + 1, rule.length);
      //             }
      //             switch (rule) {
      //                 case 'required':
      //                     if (i.val() === '') {
      //                         ferror = ierror = true;
      //                     }
      //                     break;
      //                 case 'minlen':
      //                     if (i.val().length < parseInt(exp)) {
      //                         ferror = ierror = true;
      //                     }
      //                     break;
      //             }
      //             i.next('.validate').html((ierror ? (i.attr('data-msg') != undefined ? i.attr('data-msg') : 'wrong Input') : '')).show('blind');
      //         }
      //     });
      //     if (ferror) return false;
      //     var this_form = $(this);
      //     var action = $(this).attr('action');
      //     if (!action) {
      //         this_form.find('.loading').slideUp();
      //         this_form.find('.error-message').slideDown().html('The form action property is not set!');
      //         return false;
      //     }
      //     this_form.find('.sent-message').slideUp();
      //     this_form.find('.error-message').slideUp();
      //     this_form.find('.loading').slideDown();
      //     if ($(this).data('recaptcha-site-key')) {
      //         // var recaptcha_site_key = $(this).data('recaptcha-site-key');
      //         // grecaptcha.ready(function () {
      //         //     grecaptcha.execute(recaptcha_site_key, {action: 'php_email_form_submit'}).then(function (token) {
      //         //         php_email_form_submit(this_form, action, this_form.serialize() + '&recaptcha-response=' + token);
      //         //     });
      //         // });
      //     } else {
      //         php_email_form_submit(this_form, action, this_form.serialize());
      //     }
      //     return true;
      // });
      // function php_email_form_submit(this_form, action, data) {
      //     $.ajax({
      //         type: "POST",
      //         url: action,
      //         data: data,
      //         timeout: 40000
      //     }).done(function (msg) {
      //         console.log(msg, msg.trim());
      //         if (msg.trim() == 'OK') {
      //             this_form.find('.loading').slideUp();
      //             this_form.find('.sent-message').slideDown();
      //             this_form.find("input:not(input[type=submit]), textarea").val('');
      //         } else {
      //             this_form.find('.loading').slideUp();
      //             if (!msg) {
      //                 msg = 'Form submission failed and no error message returned from: ' + action + '<br>';
      //             }
      //             this_form.find('.error-message').slideDown().html(msg);
      //         }
      //     }).fail(function (data) {
      //         console.log(data);
      //         var error_msg = "Form submission failed!<br>";
      //         if (data.statusText || data.status) {
      //             error_msg += 'Status:';
      //             if (data.statusText) {
      //                 error_msg += ' ' + data.statusText;
      //             }
      //             if (data.status) {
      //                 error_msg += ' ' + data.status;
      //             }
      //             error_msg += '<br>';
      //         }
      //         if (data.responseText) {
      //             error_msg += data.responseText;
      //         }
      //         this_form.find('.loading').slideUp();
      //         this_form.find('.error-message').slideDown().html(error_msg);
      //     });
      // }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "../assets/css/jquery-ui.css";
.vue__time-picker input.display-time {
    border: 1px solid #e3e3e3;
    width: 100%;
    height: 44px;
    padding: 10px 20px;
    font-size: 14px;
}
</style>



