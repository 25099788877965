var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"main-header header-style-one"},[_c('div',{staticClass:"header-upper"},[_c('div',{staticClass:"auto-container"},[_c('div',{staticClass:"clearfix"},[_c('div',{staticClass:"logo-outer"},[_c('div',{staticClass:"logo"},[_c('router-link',{attrs:{"to":"home"}},[_c('img',{attrs:{"src":require("@/assets/images/logo.png"),"alt":"Remises Centro","title":"Remises Centro"}})])],1)]),_c('div',{staticClass:"upper-right clearfix"},[_vm._m(0),_c('div',{staticClass:"info-box"},[_c('div',{staticClass:"icon-box"},[_c('a',{attrs:{"href":_vm.$parent.phones[0].link}},[_c('span',{staticClass:"flaticon-technology-5"})])]),_c('ul',[_vm._m(1),_c('li',[_c('a',{staticClass:"text-underline",attrs:{"href":_vm.$parent.phones[0].link}},[_vm._v(_vm._s(_vm.$parent.phones[0].phone))])])])]),_c('div',{staticClass:"info-box"},[_c('div',{staticClass:"icon-box"},[_c('a',{attrs:{"href":_vm.$parent.phones[1].link,"target":"_blank"}},[_c('span',{staticClass:"fa fa-whatsapp"})])]),_c('ul',[_vm._m(2),_c('li',[_c('a',{staticClass:"text-underline",attrs:{"href":_vm.$parent.phones[1].link,"target":"_blank"}},[_vm._v(_vm._s(_vm.$parent.phones[1].phone))])])])]),_c('div',{staticClass:"info-box btn-box"},[_c('a',{staticClass:"theme-btn",attrs:{"href":_vm.$parent.phones[1].link,"target":"_blank"}},[_c('span',{staticClass:"fa fa-whatsapp"}),_vm._v(" Enviar Whatsapp")])])])])])]),_c('div',{staticClass:"header-lower"},[_c('div',{staticClass:"auto-container"},[_c('div',{staticClass:"nav-outer clearfix"},[_c('nav',{staticClass:"main-menu"},[_vm._m(3),_c('div',{staticClass:"navbar-collapse collapse clearfix"},[_c('ul',{staticClass:"navigation clearfix"},_vm._l((_vm.$parent.menu),function(item){return _c('router-link',{key:item._id,attrs:{"to":item.route,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && 'current']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(route.name))])])]}}],null,true)})}),1)])]),_c('div',{staticClass:"social-links"},_vm._l((_vm.$parent.socials),function(social){return _c('a',{key:social._id,attrs:{"href":social.link}},[_c('span',{class:social.icon})])}),0)])])]),_c('div',{staticClass:"sticky-header"},[_c('div',{staticClass:"auto-container clearfix"},[_vm._m(4),_c('div',{staticClass:"right-col pull-right"},[_c('nav',{staticClass:"main-menu"},[_vm._m(5),_c('div',{staticClass:"navbar-collapse collapse clearfix"},[_c('ul',{staticClass:"navigation clearfix"},_vm._l((_vm.$parent.menu),function(item){return _c('router-link',{key:item._id,attrs:{"to":item.route,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'current']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(route.name))])])]}}],null,true)})}),1)])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"info-box"},[_c('div',{staticClass:"icon-box"},[_c('span',{staticClass:"flaticon-buildings"})]),_c('ul',[_c('li',[_c('strong',[_vm._v("Dirección:")])]),_c('li',[_vm._v("Elflein 7 - San Carlos de Bariloche")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('strong',[_vm._v("Llamanos Ahora:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('strong',[_vm._v("Enviar un Whatsapp:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar-header"},[_c('button',{staticClass:"navbar-toggle",attrs:{"type":"button","data-toggle":"collapse","data-target":".navbar-collapse"}},[_c('span',{staticClass:"icon-bar"}),_c('span',{staticClass:"icon-bar"}),_c('span',{staticClass:"icon-bar"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo pull-left"},[_c('a',{staticClass:"img-responsive",attrs:{"href":"index-2.html"}},[_c('img',{attrs:{"src":require("@/assets/images/logo-small.png"),"alt":"Remises Centro","title":"Remises Centro"}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar-header"},[_c('button',{staticClass:"navbar-toggle",attrs:{"type":"button","data-toggle":"collapse","data-target":".navbar-collapse"}},[_c('span',{staticClass:"icon-bar"}),_c('span',{staticClass:"icon-bar"}),_c('span',{staticClass:"icon-bar"})])])}]

export { render, staticRenderFns }